import { Routes } from '@angular/router';
import { authGuard } from './guards/auth/auth.guard';

export const routes: Routes = [
  // Default path
  { path: '', redirectTo: 'login', pathMatch: 'full' },

  // Public pages
  // ----- Landing Pages -----//
  { path: 'login', loadComponent: () => import('./pages/public/landing/login/login.page').then( m => m.LoginPage) },
  // One time create new password
  { path: 'confirm-sign-in-with-new-password', loadComponent: () => import('./pages/public/landing/confirm-sign-in-with-new-password/confirm-sign-in-with-new-password.page').then( m => m.ConfirmSignInWithNewPasswordPage) },
  // Forgot password
  { path: 'forgot-password', loadComponent: () => import('./pages/public/landing/forgot-password/forgot-password.page').then( m => m.ForgotPasswordPage) },
  { path: 'forgot-password-confirm', loadComponent: () => import('./pages/public/landing/forgot-password-confirm/forgot-password-confirm.page').then( m => m.ForgotPasswordConfirmPage) },
  // Force password reset
  { path: 'reset-password', loadComponent: () => import('./pages/public/landing/reset-password/reset-password.page').then( m => m.ResetPasswordPage) },
  // MFA confirm
  { path: 'mfa-confirm', loadComponent: () => import('./pages/public/landing/mfa-confirm/mfa-confirm.page').then( m => m.MfaConfirmPage) },
  // Verify attributes
  { path: 'verify-email', loadComponent: () => import('./pages/public/landing/verify-email/verify-email.page').then( m => m.VerifyEmailPage) },
  { path: 'verify-phone-number', loadComponent: () => import('./pages/public/landing/verify-phone-number/verify-phone-number.page').then( m => m.VerifyPhoneNumberPage) },

  // Secure pages
  // ----- Dashboard -----//
  { path: 'dashboard', loadComponent: () => import('./pages/secure/dashboard/dashboard.page').then( m => m.DashboardPage), canActivate: [authGuard] },
  { path: 'all-warrants', loadComponent: () => import('./pages/secure/all-warrants/all-warrants.page').then( m => m.AllWarrantsPage), canActivate: [authGuard] },
  { path: 'ready-to-serve', loadComponent: () => import('./pages/secure/ready-to-serve/ready-to-serve.page').then( m => m.ReadyToServePage), canActivate: [authGuard] },
  { path: 'completed', loadComponent: () => import('./pages/secure/completed/completed.page').then( m => m.CompletedPage), canActivate: [authGuard] },
  // ----- Warrants ----- //
  { path: 'report-number', loadComponent: () => import('./pages/secure/warrants/report-number/report-number.page').then( m => m.ReportNumberPage), canActivate: [authGuard] },
  { path: 'offenses', loadComponent: () => import('./pages/secure/warrants/offenses/offenses.page').then( m => m.OffensesPage), canActivate: [authGuard] },
  { path: 'searches', loadComponent: () => import('./pages/secure/warrants/searches/searches.page').then( m => m.SearchesPage), canActivate: [authGuard] },
  { path: 'grounds', loadComponent: () => import('./pages/secure/warrants/grounds/grounds.page').then( m => m.GroundsPage), canActivate: [authGuard] },
  { path: 'evidences', loadComponent: () => import('./pages/secure/warrants/evidences/evidences.page').then( m => m.EvidencesPage), canActivate: [authGuard] },
  { path: 'narrative', loadComponent: () => import('./pages/secure/warrants/narrative/narrative.page').then( m => m.NarrativePage), canActivate: [authGuard] },
  { path: 'service-times', loadComponent: () => import('./pages/secure/warrants/service-times/service-times.page').then( m => m.ServiceTimesPage), canActivate: [authGuard] },
  { path: 'optional-items', loadComponent: () => import('./pages/secure/warrants/optional-items/optional-items.page').then( m => m.OptionalItemsPage), canActivate: [authGuard] },
  { path: 'endorsement', loadComponent: () => import('./pages/secure/warrants/endorsement/endorsement.page').then( m => m.EndorsementPage), canActivate: [authGuard] },
  { path: 'department-approval', loadComponent: () => import('./pages/secure/warrants/department-approval/department-approval.page').then( m => m.DepartmentApprovalPage), canActivate: [authGuard] },
  { path: 'warrant-analytics', loadComponent: () => import('./pages/secure/warrants/warrant-analytics/warrant-analytics.page').then( m => m.WarrantAnalyticsPage), canActivate: [authGuard] },
  { path: 'company-info', loadComponent: () => import('./pages/secure/warrants/company-info/company-info.page').then( m => m.CompanyInfoPage), canActivate: [authGuard] },
  { path: 'accounts', loadComponent: () => import('./pages/secure/warrants/accounts/accounts.page').then( m => m.AccountsPage), canActivate: [authGuard] },
  { path: 'special-orders', loadComponent: () => import('./pages/secure/warrants/special-orders/special-orders.page').then( m => m.SpecialOrdersPage), canActivate: [authGuard] },
  { path: 'preview', loadComponent: () => import('./pages/secure/warrants/preview/preview.page').then( m => m.PreviewPage), canActivate: [authGuard] },
  { path: 'court-manual-review', loadComponent: () => import('./pages/secure/warrants/court-manual-review/court-manual-review.page').then( m => m.CourtManualReviewPage), canActivate: [authGuard] },
  { path: 'court-response', loadComponent: () => import('./pages/secure/warrants/court-response/court-response.page').then( m => m.CourtResponsePage), canActivate: [authGuard] },
  { path: 'warrant-service', loadComponent: () => import('./pages/secure/warrants/warrant-service/warrant-service.page').then( m => m.WarrantServicePage), canActivate: [authGuard]  },
  { path: 'warrant-return', loadComponent: () => import('./pages/secure/warrants/warrant-return/warrant-return.page').then( m => m.WarrantReturnPage), canActivate: [authGuard] },
  // ----- User -----//
  { path: 'account', loadComponent: () => import('./pages/secure/user/account/account.page').then( m => m.AccountPage), canActivate: [authGuard] },
];
