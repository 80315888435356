import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Warrant } from 'src/app/classes/warrants/warrant/warrant';
import { DebugService } from '../debug-service/debug.service';
import { Router } from '@angular/router';
import { UtiltyService } from '../utility-service/utilty.service';
import { AnalyticsService } from '../analytics-service/analytics.service';
import { ErrorService } from '../error-service/error.service';
import { DynamoService } from '../dynamo-service/dynamo.service';
import { User } from 'src/app/classes/users/user/user';
import { UiService } from '../ui-service/ui.service';
import { Analytic } from 'src/app/classes/analytics/analytic/analytic';
import { AgencyService } from '../agency-service/agency.service';
import { Offense } from 'src/app/classes/warrants/offense/offense';
import { Search } from 'src/app/classes/warrants/search/search';
import { Evidence } from 'src/app/classes/warrants/evidence/evidence';
import { ServiceArea } from 'src/app/classes/warrants/returns/service-area/service-area';
import { S3Service } from '../s3-service/s3.service';
import { IntercessorService } from '../intercessor-service/intercessor.service';
import { PartialWarrant } from 'src/app/classes/warrants/partial-warrant/partial-warrant';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class WarrantService {
  /** Name of the service */
  private serviceName = 'warrant-service';
  /** Blank warrant object */
  private blankWarrant: Warrant = {};
  /** Blank warrants object */
  private blankWarrants: Warrant[] = [];

  /** The current warrant subject */
  public currentWarrantSubject = new BehaviorSubject<Warrant>(this.blankWarrant);
  /** The current warrants sunject */
  public currentWarrantsSubject = new BehaviorSubject<Warrant[]>(this.blankWarrants);

  constructor(
    private debugService: DebugService,
    private errorService: ErrorService,
    private router: Router,
    private analyticsService: AnalyticsService,
    private agencyService: AgencyService,
    private dynamoService: DynamoService,
    private s3Service: S3Service,
    private intercessorService: IntercessorService,
    private utilityService: UtiltyService,
    private uiService: UiService,
    private domSanitizer: DomSanitizer,
  ) {
    this.currentWarrantSubject.subscribe((warrant) => {
      this.debugService.logData(`${this.serviceName} - currentWarrantSubject - New Value:`, warrant);
    });
    this.currentWarrantsSubject.subscribe((warrants) => {
      this.debugService.logData(`${this.serviceName} - currentWarrantsSubject - New Value:`, warrants);
    });
  }

  //* ----- Dynamo
  //----------------------------------------------
  //----------------------------------------------
  //-----------------WARRANTS---------------------
  //----------------------------------------------
  //----------------------------------------------
  /**
   * Create Warrant in the dynamo database
   * @param warrant The warrant being added to the dynamo database
   * @returns RestApi post response
   */
  public createWarrant = async (warrant: Warrant) => {
    try {
      return await this.dynamoService.handleCreateWarrant(warrant);
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - createWarrant`, error);
    }
  }

  /**
   * Get Warrant in the dynamo database
   * @param warrantId The warrant id of the warrant being pulled from the database
   * @returns Warrant object
   */
  public getWarrant = async (warrantId: string) => {
    try {
      const warrantApiResponse = await this.dynamoService.handleGetWarrant(warrantId);
      this.debugService.logData(`${this.serviceName} - getWarrant - warrantApiResponse:`, warrantApiResponse);
      if(warrantApiResponse.statusCode === 200) {
        const dynamoWarrant = await warrantApiResponse.body.json();
        this.debugService.logData(`${this.serviceName} - getWarrant - warrant body json:`, dynamoWarrant);
        return dynamoWarrant as Warrant;
      } else {
        throw new Error('Invalid get warrant response');
      }
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - getWarrant`, error);
    }
  }

  /**
   * Update Warrant in the dynamo database
   * @param updatedWarrant The warrant being updated in the dynamo database
   * @returns RestApi put resonse
   */
  public updateWarrant = async (updatedWarrant: Warrant) => {
    try {
      return await this.dynamoService.handleUpdateWarrant(updatedWarrant);
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - updateWarrant`, error);
    }
  }

  /**
   * Delete Warrant in the dynamo database
   * @param warrantId The id of the warrant to be deleted from dynamo
   * @returns RestApi delete response
   */
  public deleteWarrant = async (warrantId: string) => {
    try {
      // Remove warrant from dynamo
      const response = await this.dynamoService.handleDeleteWarrant(warrantId);
      // Remove warrant from local storage
      this.debugService.logData(`${this.serviceName} - deleteWarrant - size before deletion`, this.getCurrentWarrants().length);
      const newWarrantList = this.utilityService.primativeCopy(this.getCurrentWarrants().filter(warrant => warrant.id !== warrantId));
      this.debugService.logData(`${this.serviceName} - deleteWarrant - size after deletion`, newWarrantList.length);
      this.setCurrentWarrants(newWarrantList);
      return response;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - deleteWarrant`, error);
    }
  }

  /**
   * Load all Warrants in the dynamo database
   * @returns RestApi get response
   */
  public loadAllWarrants = async () => {
    try {
      const warrantsApiResponse = await this.dynamoService.handleLoadAllWarrants();
      this.debugService.logData(`${this.serviceName} - loadAllWarrants - warrantsApiResponse:`, warrantsApiResponse);
      if(warrantsApiResponse.statusCode === 200) {
        const dynamoWarrants = await warrantsApiResponse.body.json();
        this.debugService.logData(`${this.serviceName} - loadAllWarrants - warrant body json:`, dynamoWarrants);
        return dynamoWarrants as Warrant[];
      } else {
        throw new Error('Invalid get warrants response');
      }
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - loadAllWarrants`, error);
    }
  }

  /**
   * Load all Warrants in the dynamo database from a given user
   * @param userId Id of the user
   * @returns RestApi get response
   */
  public loadAllWarrantsByCreator = async (userId: string) => {
    try {
      const warrantsApiResponse = await this.dynamoService.handleLoadAllWarrantsByCreator(userId);
      this.debugService.logData(`${this.serviceName} - loadAllWarrantsByCreator - warrantsApiResponse:`, warrantsApiResponse);
      if(warrantsApiResponse.statusCode === 200) {
        const dynamoWarrants = await warrantsApiResponse.body.json();
        this.debugService.logData(`${this.serviceName} - loadAllWarrantsByCreator - warrant body json:`, dynamoWarrants);
        return dynamoWarrants as Warrant[];
      } else {
        throw new Error('Invalid get warrants response');
      }
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - loadAllWarrantsByCreator`, error);
    }
  }

  //----------------------------------------------
  //----------------------------------------------
  //-----------------OFFENSES---------------------
  //----------------------------------------------
  //----------------------------------------------
  
  /**
   * Get offenses by warrant id from the dynamo database
   * @param warrantId The warrant id of the offenses being pulled from the database
   * @param replication If the offenses are being loaded for replication
   * @returns Offense array
   */
  public loadOffensesByWarrant = async (warrantId: string, replication = false) => {
    try {
      // Get offenses from dynamo
      const offensesApiResponse = await this.dynamoService.handleGetOffensesByWarrant(warrantId);
      this.debugService.logData(`${this.serviceName} - loadOffensesByWarrant - offensesApiResponse:`, offensesApiResponse);
      if(offensesApiResponse.statusCode === 200) { // Successful get offenses response
        const dynamoOffenses = await offensesApiResponse.body.json();
        this.debugService.logData(`${this.serviceName} - loadOffensesByWarrant - offenses body json:`, dynamoOffenses);
        // Update local storage
        if(!replication) {
          const currentWarrant = this.getCurrentWarrant();
          currentWarrant.offenses = dynamoOffenses as Offense[];
          this.setCurrentWarrant(currentWarrant);
        }
        return dynamoOffenses as Offense[]; // Not sure if this is needed
      } else {
        throw new Error('Invalid get offenses response');
      }
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - loadOffensesByWarrant`, error);
    }
  }

  /**
   * Update offense in the offense dynamo table
   * @param updatedOffense Offense to be updated
   * @returns RestApi put response
   */
  public updateOffense = async (updatedOffense: any) => {
    try {
      this.debugService.logData(`${this.serviceName} - updateOffense - updatedOffense:`, updatedOffense);
      return await this.dynamoService.handleUpdateOffense(updatedOffense);
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - updateOffense`, error);
    }
  }

  /**
   * Create offense in the offense dynamo table
   * @param offense Offense to be created
   * @returns RestApi post response
   */
  public deleteOffense = async (offenseId: string) => {
    try {
      return await this.dynamoService.handleDeleteOffense(offenseId);
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - deleteOffense`, error);
    }
  }

  
  /**
   * Save offenses to the current warrant and update them in the dynamo database
   * @param offenses The offenses to be saved
   */
  public updateOffenses = async (offenses: Offense[]) => {
    try {
      this.debugService.logData(`${this.serviceName} - saveOffenses - offenses:`, offenses);
      offenses.forEach(async offense => {
        if(offense.id) {
          await this.updateOffense(offense);
        } else {
          throw new Error('Offense id is missing');
        }
      });
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - saveOffenses`, error);
    }
  }

  //----------------------------------------------
  //----------------------------------------------
  //-----------------Searches---------------------
  //----------------------------------------------
  //----------------------------------------------
  /**
   * Get searches by warrant id from the dynamo database
   * @param warrantId The warrant id of the searches being pulled from the database
   * @param replication If the searches are being loaded for replication
   * @returns Search array
   */
  public loadSearchesByWarrant = async (warrantId: string, replication = false) => {
    try {
      // Get searches from dynamo
      const searchesApiResponse = await this.dynamoService.handleGetSearchesByWarrant(warrantId);
      this.debugService.logData(`${this.serviceName} - loadSearchesByWarrant - searchesApiResponse:`, searchesApiResponse);
      if(searchesApiResponse.statusCode === 200) { // Successful get searches response
        const dynamoSearches = await searchesApiResponse.body.json();
        this.debugService.logData(`${this.serviceName} - loadSearchesByWarrant - searches body json:`, dynamoSearches);
        // Update local storage
        if(!replication) {
          const currentWarrant = this.getCurrentWarrant();
          currentWarrant.searches = dynamoSearches as Search[];
          this.setCurrentWarrant(currentWarrant);
        }
        return dynamoSearches as Search[];
      } else {
        throw new Error('Invalid get searches response');
      }
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - loadSearchesByWarrant`, error);
    }
  }

  /**
   * Update search in the search dynamo table
   * @param updatedSearch Search to be updated
   * @returns RestApi put response
   */
  public updateSearch = async (updatedSearch: Search) => {
    try {
      return await this.dynamoService.handleUpdateSearch(updatedSearch);
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - updateSearch`, error);
    }
  }

  
  /**
   * Delete search in the search dynamo table
   * @param searchId The id of the search to be deleted
   * @returns RestApi delete response
   */
  public deleteSearch = async (searchId: string) => {
    try {
      return await this.dynamoService.handleDeleteSearch(searchId);
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - deleteSearch`, error);
    }
  }

  /**
   * Save searches to the current warrant and update them in the dynamo database
   * @param searches The searches to be saved
   */
  public updateSearches = async (searches: Search[]) => {
    try {
      this.debugService.logData(`${this.serviceName} - updateSearches - searches:`, searches);
      searches.forEach(async search => {
        if(search.id) {
          await this.updateSearch(search);
        } else {
          throw new Error('Search id is missing');
        }
      });
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - updateSearches`, error);
    }
  }

  //----------------------------------------------
  //----------------------------------------------
  //-----------------Evidences--------------------
  //----------------------------------------------
  //----------------------------------------------
  /**
   * Get evidences by warrant id from the dynamo database
   * @param warrantId The warrant id of the evidences being pulled from the database
   * @param replication If the evidences are being loaded for replication
   * @returns Evidence array
   */
  public loadEvidencesByWarrant = async (warrantId: string, replication = false) => {
    try {
      // Get evidences from dynamo
      const evidencesApiResponse = await this.dynamoService.handleGetEvidencesByWarrant(warrantId);
      this.debugService.logData(`${this.serviceName} - loadEvidencesByWarrant - evidencesApiResponse:`, evidencesApiResponse);
      if(evidencesApiResponse.statusCode === 200) { // Successful get evidences response
        const dynamoEvidences = await evidencesApiResponse.body.json();
        this.debugService.logData(`${this.serviceName} - loadEvidencesByWarrant - evidences body json:`, dynamoEvidences);
        // Update local storage
        if(!replication) {
          const currentWarrant = this.getCurrentWarrant();
          currentWarrant.evidences = dynamoEvidences as Evidence[];
          this.setCurrentWarrant(currentWarrant);
        }
        return dynamoEvidences as Evidence[];
      } else {
        throw new Error('Invalid get evidences response');
      }
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - loadEvidencesByWarrant`, error);
    }
  }

  /**
   * Update evidence in the evidence dynamo table
   * @param updatedEvidence Evidence to be updated
   * @returns RestApi put response
   */
  public updateEvidence = async (updatedEvidence: Evidence) => {
    try {
      return await this.dynamoService.handleUpdateEvidence(updatedEvidence);
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - updateEvidence`, error);
    }
  }

  /**
   * Delete evidence in the evidence dynamo table
   * @param evidenceId The id of the evidence to be deleted
   * @returns RestApi delete response
   */
  public deleteEvidence = async (evidenceId: string) => {
    try {
      return await this.dynamoService.handleDeleteEvidence(evidenceId);
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - deleteEvidence`, error);
    }
  }

  /**
   * Update the evidences array with the updated evidence
   * @param evidences The updated evidences array
   */
  public updateEvidences = async (evidences: Evidence[]) => {
    try {
      this.debugService.logData(`${this.serviceName} - updateEvidences - evidences:`, evidences);
      evidences.forEach(async evidence => {
        if(evidence.id) {
          await this.updateEvidence(evidence);
        } else {
          throw new Error('Evidence id is missing');
        }
      });
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - updateEvidences`, error);
    }
  }

  //----------------------------------------------
  //----------------------------------------------
  //-----------------Service Areas----------------
  //----------------------------------------------
  //----------------------------------------------
  /**
   * Get service areas by warrant id from the dynamo database
   * @param warrantId The warrant id of the service areas being pulled from the database
   * @param replication If the service areas are being loaded for replication
   * @returns Service Area array
   */
  public loadServiceAreasByWarrant = async (warrantId: string, replication = false) => {
    try {
      // Get service areas from dynamo
      const serviceAreasApiResponse = await this.dynamoService.handleGetServiceAreasByWarrant(warrantId);
      this.debugService.logData(`${this.serviceName} - loadServiceAreasByWarrant - serviceAreasApiResponse:`, serviceAreasApiResponse);
      if(serviceAreasApiResponse.statusCode === 200) { // Successful get service areas response
        const dynamoServiceAreas = await serviceAreasApiResponse.body.json();
        this.debugService.logData(`${this.serviceName} - loadServiceAreasByWarrant - service areas body json:`, dynamoServiceAreas);
        // Update local storage
        const currentWarrant = this.getCurrentWarrant();
        const serviceAreas = (dynamoServiceAreas as ServiceArea[]).sort((a, b) => a.sortOrder! - b.sortOrder!);
        if(!replication) {
          const searches = currentWarrant.searches;
          // Add the service areas to the corresponding searches (the id of search should match the service area searchId)
          searches!.forEach(search => {
            search.serviceAreas = [];
            serviceAreas.forEach(serviceArea => {
              if (search.id === serviceArea.searchId) {
                search!.serviceAreas!.push(serviceArea);
              }
            });
          });
          this.setCurrentWarrant(currentWarrant);
        }
        return serviceAreas;
      } else {
        throw new Error('Invalid get service areas response');
      }
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - loadServiceAreasByWarrant`, error);
    }
  }

  /**
   * Update service area in the service area dynamo table
   * @param updatedServiceArea Service Area to be updated
   * @returns RestApi put response
   */
  public updateServiceArea = async (updatedServiceArea: any) => {
    try {
      return await this.dynamoService.handleUpdateServiceArea(updatedServiceArea);
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - updateServiceArea`, error);
    }
  }

  /**
   * Delete service area in the service area dynamo table
   * @param serviceAreaId The id of the service area to be deleted
   * @returns RestApi delete response
   */
  public deleteServiceArea = async (serviceAreaId: string) => {
    try {
      return await this.dynamoService.handleDeleteServiceArea(serviceAreaId);
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - deleteServiceArea`, error);
    }
  }

  /**
   * Save service areas to the current warrant and update them in the dynamo database
   * @param serviceAreas The service areas to be saved
   */
  public updateServiceAreas = async (serviceAreas: ServiceArea[]) => {
    try {
      this.debugService.logData(`${this.serviceName} - saveServiceAreas - serviceAreas:`, serviceAreas);
      serviceAreas.forEach(async serviceArea => {
        if(serviceArea.id) {
          await this.updateServiceArea(serviceArea);
        } else {
          throw new Error('Service Area id is missing');
        }
      });
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - saveServiceAreas`, error);
    }
  }

  //----------------------------------------------
  //----------------------------------------------
  //-----------------Signature--------------------
  //----------------------------------------------
  //----------------------------------------------
  /**
   * Get the signature url from the s3 bucket
   * @param signatureKey The key of the signature
   */
  public getSignatureUrl = async ({ id, signatureImg }: Warrant) => {
    try {
      return (await this.s3Service.handleGetUrl('public', `warrant/${id}/${signatureImg}`))?.url.toString();
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - getSignatureUrl`, error);
    }
  }

  //* ----- Intercessor
  /**
   * Submit the warrant to the court
   * @param warrant Warrent to submit
   * @param courtId Court ID
   * @returns RestApi post response
   */
  public submitWarrantToCourt = async (warrant: Warrant, courtId: string) => {
    try {
      const warrantToSubmit = new PartialWarrant(warrant);
      this.debugService.logData(`${this.serviceName} - submitWarrantToCourt - warrantToSubmit:`, warrantToSubmit);
      this.debugService.logData(`${this.serviceName} - submitWarrantToCourt - courtId:`, courtId);
      const apiCourtsResponse =  await this.intercessorService.handleSubmitWarrantToCourt(warrantToSubmit, courtId);
      this.debugService.logData(`${this.serviceName} - submitWarrantToCourt - apiCourtsResponse:`, apiCourtsResponse);

      if (apiCourtsResponse.statusCode === 200) {
        const intercessorResponse = await apiCourtsResponse.body.json() as { body: string, headers: { [key: string]: string }, statusCode: number };
        this.debugService.logData(`${this.serviceName} - submitWarrantToCourt - intercessorResponse:`, intercessorResponse);
        const responseBody = JSON.parse(intercessorResponse.body);
        this.debugService.logData(`${this.serviceName} - submitWarrantToCourt - responseBody:`, responseBody);
        return intercessorResponse;
      } else {
        throw new Error('Invalid submit warrant to court response');
      }
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - submitWarrantToCourt`, error);
    }
  }

  //* ----- Preview / Download
  /**
   * Load the preview of the warrant
   * @param warrantId Warrant ID
   * @param scope Scope of the preview
   * @returns HTML string
   */
  public loadPreview = async (warrantId: string, scope: 'cover' | 'searches' | 'evidences' | 'grounds' | 'offenses' | 'service' | 'expedited' | 'affidavit' | 'all') => {
    try {
      const apiPreviewResponse = await this.dynamoService.handleLoadPreview(warrantId, scope);
      if (apiPreviewResponse.statusCode === 200) {
        const lambdaResponse = await apiPreviewResponse.body.json() as { body: string, headers: { [key: string]: string }, statusCode: number };
        this.debugService.logData(`${this.serviceName} - loadPreview - lambdaResponse:`, lambdaResponse);
        const rawHtml = lambdaResponse.body as unknown as string[];
        this.debugService.logData(`${this.serviceName} - loadPreview - rawHtml:`, rawHtml);
        // Concatenate the html
        let html = '';
        rawHtml.forEach(rawHtmlPart => {
          html += rawHtmlPart;
        });
        this.debugService.logData(`${this.serviceName} - loadPreview - html:`, html);
        const sanitizedHtml: SafeHtml = this.domSanitizer.bypassSecurityTrustHtml(html);
        return sanitizedHtml;
      } else {
        throw new Error('Invalid load preview response');
      }
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - loadPreview`, error);
    }
  }

  public downloadDoc = async (warrantId: string, scope: 'cover' | 'searches' | 'evidences' | 'grounds' | 'offenses' | 'service' | 'expedited' | 'affidavit' | 'all') => {
    try {
      const apiDownloadResponse = await this.dynamoService.handleDownloadDoc(warrantId, scope);
      if (apiDownloadResponse.statusCode === 200) {
        const lambdaResponse = await apiDownloadResponse.body.blob();
        this.debugService.logData(`${this.serviceName} - downloadDoc - lambdaResponse:`, lambdaResponse);
        return lambdaResponse;
      } else {
        throw new Error('Invalid download doc response');
      }
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - downloadDoc`, error);
    }
  }

  //* ----- Local
  /**
   * Resets the current warrant
   */
  public resetCurrentWarrant = () => {
    this.currentWarrantSubject.next(this.blankWarrant);
  }

  /**
   * Sets the current warrant
   * @param warrant Warrant to become the current warrant
   */
  public setCurrentWarrant = (warrant: Warrant) => {
    warrant.status = this.getWarrantStatus(warrant);
    this.currentWarrantSubject.next(warrant);
  }

  /**
   * Returns the current warrant
   * @returns The current warrant
   */
  public getCurrentWarrant = () : Warrant => {
    return this.currentWarrantSubject.getValue();
  }

  /**
   * Resets the current warrants
   */
  public resetCurrentWarrants = () => {
    this.currentWarrantsSubject.next(this.blankWarrants);
  }

  /**
   * Sets the current warrants
   * @param warrant Warrant to become the current warrant
   */
  public setCurrentWarrants = (warrants: Warrant[]) => {
    // Convert legacy warrant status to new status
    warrants.forEach(warrant => {
      warrant.status = this.getWarrantStatus(warrant);
    });
    this.currentWarrantsSubject.next(warrants);
  }

  /**
   * Returns the current warrants
   * @returns The current warrants
   */
  public getCurrentWarrants = () : Warrant[] => {
    return this.currentWarrantsSubject.getValue();
  }

  /**
   * Get the warrant status (legacy to new)
   * @param warrant Warrant object
   * @returns The warrant status
   */
  public getWarrantStatus = (warrant: Warrant) => {
    try {
      if (warrant.status === 'deptapproved' || warrant.status === 'courtSubmitted') { // The reason this courtSubmitted is here is because the status is not updated on return from the court (this should be changed in the future)
        if (warrant.courtStatus === 'approved') {
          return 'courtApproved';
        } else if (warrant.courtStatus === 'denied') {
          return 'courtDenied';
        } else if (warrant.courtStatus === 'submitted') {
          return 'courtSubmitted';
        }
      } else if (warrant.status === 'manualapproval') {
        return 'courtManualPending';
      }
      return warrant.status;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - getWarrantStatus`, error);
    }
  }

  /**
   * Update the warrants array with the updated warrant
   * @param updatedWarrant The updated warrant object
   */
  public updateWarrantsArray = (updatedWarrant: Warrant) => {
    const newWarrantsList = this.getCurrentWarrants();
    const updatedIndex = newWarrantsList.findIndex(warrant => warrant.id === updatedWarrant.id);
    if (updatedIndex === -1) { // Object was not found
      newWarrantsList.push(updatedWarrant);
    } else { // Object was found
      newWarrantsList[updatedIndex] = updatedWarrant;
    }
    this.setCurrentWarrants(newWarrantsList);
  }

  /**
   * Create a new Warrant
   * @param type New warrant type
   */
  public startWarrant = (currentUser: User, type: 'warrant' | 'order' | 'gps' | 'specialized' ) => {
    try {
      const currentAgency = this.agencyService.getCurrentAgency();
      this.setCurrentWarrant({
        agencyId: currentAgency.id,
        agencyName: currentAgency.agencyName,
        creatorId: currentUser.id,
        dateSetup: new Date().toISOString(),
        id: this.utilityService.getUuid(),
        status: 'draft',
        type: type,
      });
      this.analyticsService.startAnalytic(this.getCurrentWarrant(), currentUser);
      this.router.navigate(['/report-number'], { replaceUrl: true });
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - startWarrant`, error);
    }
  }

  /**
   * Load the warrant and navigate to the appropriate page
   * @param destination Page to navigate to (string should match the app.routes navigation url)
   */
  public navigateToWarrant = async (warrantId: string, destination: string) => {
    try {
      // Start blocking loading UI
      this.uiService.startBlockingLoading('Loading Warrant ...');

      // Check if warrant id is missing
      if (!warrantId) throw new Error('Missing warrant id!');

      // Set the current warrant
      this.setCurrentWarrant(await this.getWarrant(warrantId));

      // Load the offenses
      await this.loadOffensesByWarrant(warrantId);

      // Load the searches
      await this.loadSearchesByWarrant(warrantId);

      // Load the evidences
      await this.loadEvidencesByWarrant(warrantId);

      // Load the service areas
      await this.loadServiceAreasByWarrant(warrantId);

      // Set the current analytic
      this.analyticsService.setCurrentAnalytic(await this.analyticsService.getAnalytic(warrantId));

      // Navigate to the destination page
      await this.router.navigate([`/${destination}`], { replaceUrl: true });
    } catch (error) {
      // Pass the error to the error service
      throw this.errorService.passError(`${this.serviceName} - navigateToWarrant`, error);
    } finally {
      // End blocking loading UI
      this.uiService.endBlockingLoading();
    }
  }

  /**
    * Save the warrant and analytics data simultaneously locally and to dynamo
    * @param warrantPayload The warrant payload to be saved (use '{}' if no changes)
    * @param analyticsPayload The analytics payload to be saved (user '{}' if no changes)
    * @param user The user making the change
    * @param changelogAction The action being taken
    * @returns 
    */
    public masterSave = async (warrantPayload: Warrant, analyticsPayload: Analytic) => {
     try {
      // Get current warrant
      const currentWarrant = this.getCurrentWarrant();
      const currentAnalytic = this.analyticsService.getCurrentAnalytic();

      // Update Dynamo
      
      if (Object.keys(warrantPayload).length !== 0) {
        await this.updateWarrant(warrantPayload);
      }
      if (Object.keys(analyticsPayload).length !== 0) {
        await this.analyticsService.updateAnalytic(analyticsPayload);
      }

      // Get the complete warrant
      const updatedWarrant = {
        ...currentWarrant,
        ...warrantPayload
      };

      // Update the warrant
      this.setCurrentWarrant(updatedWarrant);

      // Update analytics
      this.analyticsService.setCurrentAnalytic({
        ...currentAnalytic,
        ...analyticsPayload
      });

      // Update the warrants array
      this.updateWarrantsArray(updatedWarrant);
      this.uiService.showSuccessToast('Warrant Saved');
     } catch (error) {
      this.uiService.showErrorToast('Error Saving Warrant');
      throw this.errorService.passError(`${this.serviceName} - masterSave`, error);
     }
    }

  /**
   * Set the local warrants to the dynamo warrants
   */
  public warrantsLoadToLocalStorage = async () => {
    try {
      const dynamoWarrants = await this.loadAllWarrants();
      this.debugService.logData(`${this.serviceName} - warrantsLoadToLocal - dynamoWarrants:`, dynamoWarrants);
      this.setCurrentWarrants(dynamoWarrants);
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - warrantsLoadToLocal`, error);
    }
  }

  /**
   * Set local warrants to the dynamo warrants if they don't already exist
   */
  public warrantsCheckIn = async () => {
    try {
      if(!this.getCurrentWarrants().length) {
        await this.warrantsLoadToLocalStorage();
      }
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - warrantsCheckIn`, error);
    }
  }
}
