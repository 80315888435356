import { Injectable } from '@angular/core';
import { post } from 'aws-amplify/api';
import { DebugService } from '../debug-service/debug.service';
import { ErrorService } from '../error-service/error.service';
import { PartialWarrant } from 'src/app/classes/warrants/partial-warrant/partial-warrant';
import { IntercessorRequest } from 'src/app/classes/requests/intercessor/intercessor-request/intercessor-request';
import { SubmitWarrantPayload } from 'src/app/classes/requests/intercessor/submit-warrant-payload/submit-warrant-payload';
import { LoadCourtsPayload } from 'src/app/classes/requests/intercessor/load-courts-payload/load-courts-payload';
import { UtiltyService } from '../utility-service/utilty.service';

@Injectable({
  providedIn: 'root'
})
export class IntercessorService {
  /** Service name */
  private serviceName = 'intercessor-service';

  constructor(
    private debugService: DebugService,
    private errorService: ErrorService,
    private utilityService: UtiltyService,
  ) { }

  //----- Agency -----//
  /**
   * Get the court connections for the agency
   * @param agencyId The agency ID
   * @returns RestApi post response
   */
  public handleGetAgencyCourtConnections = async (agencyId: string) => {
    try {
      const payload: LoadCourtsPayload = {
        agencyId: agencyId
      }
      const intercessorRequest: IntercessorRequest = {
        handler: 'courts',
        data: payload
      }

      const restOperation = post({
        apiName: 'cwApi',
        path: '/intercessor',
        options: {
          body: this.utilityService.primativeCopy(intercessorRequest),
        }
      });

      return await restOperation.response;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleGetAgencyCourtConnections`, error);
    }
  }

  //----- Warrant -----//
  /**
   * Submit the warrant to the court
   * @param warrant Warrent to submit
   * @param courtId Court ID
   * @returns RestApi post response
   */
  public handleSubmitWarrantToCourt = async (warrant: PartialWarrant, courtId: string) => {
    try {
      const payload: SubmitWarrantPayload = {
        searchWarrant: warrant,
        submitTo: [courtId]
      }
      const intercessorRequest: IntercessorRequest = {
        handler: 'submit',
        data: payload
      }

      this.debugService.logData(`${this.serviceName} - handleSubmitWarrantToCourt - intercessorRequest`, intercessorRequest);

      const restOperation = post({
        apiName: 'cwApi',
        path: '/intercessor',
        options: {
          body: this.utilityService.primativeCopy(intercessorRequest),
        }
      });

      return await restOperation.response;
    } catch (error) {
      throw this.errorService.passError(`${this.serviceName} - handleSubmitWarrantToCourt`, error);
    }
  }
}
