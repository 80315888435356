import { Warrant } from "../warrant/warrant";

export class PartialWarrant {
  constructor(
    {
      affiantLastName,
      affiantTitle,
      agencyId,
      agencyName,
      id,
      isExpedited,
      affiantCell,
      tsSubmittedToCourt,
      type,
      status,
      courtStatus,
    }: Warrant
  ) {
    this.affiantLastName = affiantLastName;
    this.affiantTitle = affiantTitle;
    this.agencyId = agencyId;
    this.agencyName = agencyName;
    this.id = id;
    this.isExpedited = isExpedited;
    this.affiantCell = affiantCell;
    this.tsSubmittedToCourt = tsSubmittedToCourt;
    this.type = type;
    this.status = status;
    this.courtStatus = courtStatus || 'submitted';
  }

  /** Last name of the affiant */
  affiantLastName?: string;
  /** Title of the affiant */
  affiantTitle?: string;
  /** AWS ID of the Agency that created the warrant */
  agencyId?: string;
  /** Name of the agency */
  agencyName?: string;
  /** AWS ID of the search warrant */
  id?: string;
  /** Expedited Review is requested */
  isExpedited?: boolean;
  /** Cell number of the affiant */
  affiantCell?: string;
  /** Timestamp when the warrant was submitted to the court */
  tsSubmittedToCourt?: string;
  /** What kind of warrant it is */
  type?: string;
  /** Status of the warrant */
  status?: string;
  /** Court status of the warrant */
  courtStatus?: string;
}
